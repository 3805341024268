jQuery(document).ready(function($){

    $('.steps-slider').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        fade: true,
        cssEase: 'ease-out',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>',
        appendArrows: '.steps-slider__nav',
        adaptiveHeight: true,
    });

    $('.slider-home').slick({
        dots: true,
        speed: 1000,
        infinite: true,
        autoplay: true,
        fade: true,
        cssEase: 'ease-out',
        arrows: false,
        appendDots: '.slide__dots',
        responsive: [
            {
                breakpoint: 640,
                settings: "unslick",
            },
        ],
    });

});
