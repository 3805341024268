jQuery(document).ready(function($){

    function resize_tiles() {
        $(".shape-square").each(function() {
            var width = $(this).width();
            $(this).height(width);

            if ($("body").hasClass("home")) {
                var tile_margin = -(width / 2);
                $(this).css("margin-top", tile_margin + "px");
            }
        });
    }

    resize_tiles();

    $(window).resize(resize_tiles);

});
